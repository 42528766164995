import React from 'react';

import { Text, TextField } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  ApiTypesV1QuestionAnswer,
  ValidationError,
} from '@wix/social-groups-api';
import { DATA_HOOKS } from '../dataHooks';
import { classes, st } from './MembershipQuestion.st.css';
import { BlackAndWhiteTextField } from 'common/components/BlackAndWhiteTextField';

interface QuestionAnswerProps {
  questionAnswer: ApiTypesV1QuestionAnswer;
  index: number;
  forceFocus: boolean;
  error?: ValidationError;
  className?: string;
  onChange(questionId: string, answer: string): void;
  onBlur(questionId: string): void;
}

export const QuestionAnswer: React.FC<QuestionAnswerProps> = (props) => {
  const answerRef = React.useRef<HTMLInputElement>();
  const { t } = useTranslation();
  const { questionAnswer, forceFocus, index, error, className } = props;

  const handleAnswerOnChange = (evt: any) => {
    const answer = evt.target.value;
    props.onChange(props.questionAnswer.question?.questionId!, answer);
  };

  const handleAnswerOnBlur = () => {
    props.onBlur(props.questionAnswer.question?.questionId!);
  };

  const errorMessage = React.useMemo(() => {
    if (error === undefined) {
      return '';
    }

    if (error === ValidationError.REQUIRED) {
      return t('groups-web.membership-questions.response.error');
    }

    if (error === ValidationError.MAX_LENGTH) {
      return t('groups-web.membership-questions.response.error-max_length');
    }

    return '';
  }, [error, t]);

  if (answerRef.current && forceFocus) {
    answerRef.current.focus();
  }

  return (
    <div
      className={st(classes.root, {}, className)}
      data-hook={DATA_HOOKS.questionAnswerRoot}
    >
      <div className={classes.questionWrap}>
        <Text className={classes.question}>
          {index}. {questionAnswer.question?.textQuestion}
          {questionAnswer.question?.required ? '*' : ''}
        </Text>
      </div>
      <BlackAndWhiteTextField
        className={classes.questionAnswerInput}
        inputRef={setAnswerRef}
        autoFocus={forceFocus}
        defaultValue={questionAnswer.textAnswer!}
        error={error !== undefined}
        errorMessage={errorMessage}
        onChange={handleAnswerOnChange}
        onBlur={handleAnswerOnBlur}
        placeholder={t('groups-web.membership-questions.response.placeholder')}
      />
    </div>
  );

  function setAnswerRef(inputElement: HTMLInputElement) {
    answerRef.current = inputElement;
  }
};

QuestionAnswer.displayName = 'QuestionAnswer';
